import React, { FC } from 'react';
import Video from '../../../components/pagesComponents/MainPage/Video';
import statflo from '../../../assets/img/companies/statflo-black.svg';
import videoStatflo from '../../../assets/video/videostatflo.mp4';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import FixedButton from '../../../components/common/FixedButton';

import StatfloLayout from '../../../components/PagesLayouts/StatfloLayout';

const ProjectGallery = () => {
    return (
        <StatfloLayout>
            <div className="scope-of-project__video">
                <Video
                    logo={statflo}
                    slogan="“Fantastic, I'm totally blown away by Blackbird services, tech expertise, and each team member individually. Sometimes, I even forget where our staff is and where  Blackbirders are. ”"
                    position="Ian Gervais, VP of Product"
                    video={videoStatflo}
                />
            </div>
            <div className="scope-of-project__body scope-of-project__body--mw">
                <Links />
            </div>
            <FixedButton/>
        </StatfloLayout>
    );
};

export default ProjectGallery;
